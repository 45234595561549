<template>
  <div class="userCenterBox">
    <div v-for="(item, index) in person_setting" :key="index" class="listBox">
      <p>{{ item.name }}</p>
      <div class="configBox">
        <div class="configItem" :style="{ border: item.wxapp_show ? '1px solid #3773FF' : '1px solid #e0e0e0', background: item.wxapp_show ? '#EAF2FF' : '' }">
          <div>微信小程序</div>
          <el-switch v-model="item.wxapp_show" active-color="#3370FF" inactive-color="#BCBCBC" :active-value="true" :inactive-value="false"> </el-switch>
        </div>
        <div class="configItem" :style="{ border: item.ali_show ? '1px solid #3773FF' : '1px solid #e0e0e0', background: item.ali_show ? '#EAF2FF' : '' }">
          <div>支付宝小程序</div>
          <el-switch v-model="item.ali_show" active-color="#3370FF" inactive-color="#BCBCBC" :active-value="true" :inactive-value="false"> </el-switch>
        </div>
        <div class="configItem" :style="{ border: item.fast_show ? '1px solid #3773FF' : '1px solid #e0e0e0', background: item.fast_show ? '#EAF2FF' : '' }">
          <div>快应用</div>
          <el-switch v-model="item.fast_show" active-color="#3370FF" inactive-color="#BCBCBC" :active-value="true" :inactive-value="false"> </el-switch>
        </div>
      </div>
    </div>
    <!-- <div class="listBox">
      <p></p>
      <div class="preservation" @click="preservation">保存</div>
    </div> -->
    <Preservation @preservation="preservation"></Preservation>
  </div>
</template>

<script>
import Preservation from '@/components/preservation'
export default {
  data() {
    return {
      person_setting: [
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的订单',
          path: '/pages/orderCenter/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '会员卡',
          path: '/pages/registerMembership/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的地址',
          path: '/pages/address/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的收藏',
          path: '/pages/myCollection/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的购物车',
          path: '/pages/cart/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的客服',
          path: '/packageA/pages/chatRoom/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '我的优惠券',
          path: '/pages/myCoupon/index',
          wxapp_show: true
        },
        {
          ali_show: true,
          fast_show: true,
          icon: 'ri-layout-masonry-line',
          manager: 1,
          name: '商户入驻',
          path: '/pages/merchants/index',
          wxapp_show: true
        }
      ],
      isFlag: true,
      // 是否有线下购 -> 默认没有
      haveXian: false
    }
  },
  created() {
    this.getInfo()
  },
  components: {
    Preservation
  },
  methods: {
    getInfo() {
      let that = this
      this.$axios.post(this.$api.shopDiy.PersonUpList, {}).then((res) => {
        if (res.code == 0) {
          if (res.result.person_setting == null) return
          that.person_setting = res.result.person_setting
          that.person_setting.map((item) => {
            if (item.name == '线下购') {
              that.haveXian = true
            }
            if (item.ali_show == 'true') {
              item.ali_show = true
            } else {
              item.ali_show = false
            }
            if (item.wxapp_show == 'true') {
              item.wxapp_show = true
            } else {
              item.wxapp_show = false
            }
            if (item.fast_show == 'true') {
              item.fast_show = true
            } else {
              item.fast_show = false
            }
          })
          if (!that.haveXian) {
            that.person_setting.push({
              ali_show: true,
              fast_show: true,
              icon: 'ri-layout-masonry-line',
              manager: 1,
              name: '线下购',
              path: '/pages/offline/index',
              wxapp_show: true
            })
          }
        }
      })
    },
    // 保存配置
    preservation() {
      if (!this.isFlag) return
      let that = this
      this.isFlag = false
      if (!that.haveXian) {
        this.person_setting.map((item) => {
          if (item.name == '我的订单') {
            item.path = '/pages/orderCenter/index'
          } else if (item.name == '会员卡') {
            item.path = '/pages/registerMembership/index'
          } else if (item.name == '我的地址') {
            item.path = '/pages/address/index'
          } else if (item.name == '我的收藏') {
            item.path = '/pages/myCollection/index'
          } else if (item.name == '我的购物车') {
            item.path = '/pages/cart/index'
          } else if (item.name == '我的客服') {
            item.path = '/packageA/pages/chatRoom/index'
          } else if (item.name == '我的优惠券') {
            item.path = '/pages/myCoupon/index'
          } else if (item.name == '商户入驻') {
            item.path = '/pages/merchants/index'
          } else if (item.name == '线下购') {
            item.path = '/pages/offline/index'
          }
        })
      }
      setTimeout(() => {
        that.isFlag = true
      }, 1000)
      this.$axios
        .post(this.$api.shopDiy.setPersonUp, {
          person_setting: that.person_setting
        })
        .then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            return
          }
          that.$message({
            message: '保存失败',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.userCenterBox {
  height: 100%;
  padding: 50px 0 60px 50px;
  background-color: #ffff;
  font-size: 14px;
  overflow-y: auto;
  .listBox {
    margin-bottom: 20px;
    display: flex;
    line-height: 70px;
    p {
      width: 150px;
      text-align: right;
      margin-right: 50px;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .configBox {
      display: flex;
      width: 860px;
      justify-content: space-between;
      .configItem {
        width: 260px;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        border-radius: 8px;
      }
    }
    .preservation {
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-color: #409eff;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
